.homepage-go-to-login-button {
    width: 400px;
    height: 200px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    color: white;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}