.upload-plan-widget-external-container {
    width: 49%;
    height: 150px;
    border-radius: 20px;
    border: 1px rgba(217, 217, 217, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-plan-widget-internal-container {
    width: 90%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.upload-plan-widget-uploaded-file-background {
    background-color: rgba(220, 234, 255, 1);
}

.upload-plan-widget-left-text {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}

.upload-plan-widget-remove-button {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.upload-plan-widget-x-image {
    line-height: 0;
    margin-right: 10px;
}