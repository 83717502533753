.platform-experts-customers-single-patient-exams-page-container {
    width: 90%;
    height: 95%;
    align-self: end;
}

.platform-experts-customers-single-patient-exams-page-title-row {
    height: 90px;
    width: 89%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.platform-experts-customers-single-patient-exams-page-title-group {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.platform-experts-customers-single-patient-exams-page-title-group-name {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}

.platform-experts-customers-single-patient-exams-page-title-group-heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
}

.platform-experts-customers-single-patient-exams-page-content {
    width: 100%;
    height: calc(100% - 90px);
    overflow-y: auto;
}


.platform-experts-customers-single-patient-exams-page-content-container {
    height: 100%;
    width: 89%;
}

.platform-experts-customers-single-patient-exams-page-content-heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
}

.platform-experts-customers-single-patient-exams-page-blood-exams-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.platform-experts-customers-single-patient-exams-page-other-exams-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.platform-experts-customers-single-patient-exams-page-exam-container {
    width: 32%;
    height: 596px;
    border-radius: 10px;
    border: 1px #EAEAEA solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    margin-bottom: 20px;
}

.platform-experts-customers-single-patient-exams-page-exam-upper-element-container {
    width: 100%;
    height: 474px;
    background-color: lightgray;
    border-radius: 10px 10px 0 0;
}

.platform-experts-customers-single-patient-exams-page-exam-bottom-element-container {
    width: 100%;
    height: calc(100% - 474px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.platform-experts-customers-single-patient-exams-page-exam-bottom-element-internal-container {
    width: 80%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.platform-experts-customers-single-patient-exams-page-exam-bottom-element-internal-container-date-and-name {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.platform-experts-customers-single-patient-exams-page-exam-bottom-element-internal-container-date {
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
}

.platform-experts-customers-single-patient-exams-page-exam-bottom-element-internal-container-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
}