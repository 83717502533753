.customers-apply-filters-container {
    width: 100%;
    height: 261px;
    border-radius: 20px;
    border: 1px black solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.customers-apply-filters-internal-container {
    width: 95%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.customers-apply-filters-heading {
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
}

.customers-apply-filters-filters-container {
    width: 100%;
    height: 66px;
    display: flex;
    justify-content: space-between;
}

.customers-apply-filters-single-filter-container {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px black solid;
}

.customers-apply-filters-single-filter-label {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
}

.customers-apply-filters-input-field {
    height: 100%;
    border: none;
}

.customers-apply-filters-input-field:focus {
    outline: none;
}

.customers-apply-filters-input-field::placeholder {
    color: rgba(95, 95, 95, 1);
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.5px;
}

.customers-apply-filters-buttons-row {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.customers-erase-filters-button {
    width: 250px;
    height: 55px;
    border-radius: 5px;
    border: none;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-right: 20px;
}

.customers-apply-filters-button {
    width: 250px;
    height: 55px;
    border-radius: 5px;
    border: none;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}