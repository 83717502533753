.vertical-navbar {
    background-color: rgba(8, 20, 40, 1);
    width: 120px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0px;
  }

  .vertical-navbar-intenal {
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .navbar-categories-container {
    width: 100%;
    height: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .navbar-single-category-container {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    cursor: pointer;
  }

  .navbar-category-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: white;
  }