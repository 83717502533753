.platform-experts-customers-single-patient-create-plan-container {
    width: 90%;
    height: 95%;
    align-self: end;
}

.platform-experts-customers-single-patient-create-plan-title-row {
    height: 100px;
    width: 89%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.platform-experts-customers-single-patient-create-plan-title-row-left-element {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}

.platform-experts-customers-single-patient-create-plan-title-row-patient-name {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}

.platform-experts-customers-single-patient-create-plan-title-row-heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
}

.platform-experts-customers-single-patient-create-plan-content {
    height: calc(100% - 200px);
    width: 100%;
    overflow-y: auto;
}

.platform-experts-customers-single-patient-create-plan-content-container {
    height: 100%;
    width: 89%;
}

.platform-experts-customers-single-patient-create-plan-buttons-row {
    width: 89%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.platform-experts-customers-single-patient-create-plan-undo-button {
    width: 250px;
    height: 55px;
    border: 1px black solid;
    text-transform: uppercase;
    cursor: pointer;
    background-color: white;
    border-radius: 5px;
    margin-right: 20px;
}

.platform-experts-customers-single-patient-create-plan-publish-button {
    width: 250px;
    height: 55px;
    border: none;
    text-transform: uppercase;
    border-radius: 5px;
    margin-left: 20px;
    color: white;
}