.platform-experts-customers-single-patient-answer-page-single-quiz-container {
    width: 90%;
    height: 95%;
    align-self: end;
}

.platform-experts-customers-single-patient-answer-page-single-quiz-title-row {
    height: 100px;
    width: 89%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.platform-experts-customers-single-patient-answer-page-single-quiz-content {
    width: 100%;
    height: calc(100% - 100px);
    overflow-y: auto;
}

.platform-experts-customers-single-patient-answer-page-single-quiz-content-internal {
    width: 89%;
    height: 100%;
}

.platform-experts-customers-single-patient-answer-page-single-quiz-back-button {
    display: flex;
    cursor: pointer;
}

.platform-experts-customers-single-patient-answer-page-single-quiz-back-button-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.platform-experts-customers-single-patient-answer-page-single-quiz-back-button-image {
    margin-right: 15px;
}

.platform-experts-customers-single-patient-answer-page-single-quiz-name {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
}