.platform-experts-customers-single-patient-answer-page-container {
    width: 90%;
    height: 95%;
    align-self: end;
}

.platform-experts-customers-single-patient-answer-page-title-row {
    height: 60px;
    width: 89%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.platform-experts-customers-single-patient-answer-page-title {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
}

.platform-experts-customers-single-patient-answer-page-content {
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: auto;
}


.platform-experts-customers-single-patient-answer-page-content-container {
    height: 100%;
    width: 89%;
}

.platform-experts-customers-single-patient-answer-page-content-updates-section {
    border: 1px black solid;
    border-radius: 5px;
}

.platform-experts-customers-single-patient-answer-page-content-updates-heading-row {
    display: flex;
    align-items: center;
}

.platform-experts-customers-single-patient-answer-page-content-updates-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-right: 20px;
}

.platform-experts-customers-single-patient-answer-page-content-updates-see-all {
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
    color: rgba(59, 130, 247, 1);
    margin-left: 20px;
    cursor: pointer;
}

.platform-experts-customers-single-patient-answer-page-single-question-line {
    width: 100%;
    height: 150px;
    border-radius: 10px;
    border: 1px black solid;
    margin-top: 10px;
    margin-bottom: 10px;
}

.platform-experts-customers-single-patient-answer-page-content-survey-cards-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.platform-experts-customers-single-patient-answer-page-survey-card-placeholder {
    width: 32%;
    height: 237px;
}