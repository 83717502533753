.expert-tips-widget-full-container {
    display: flex;
    justify-content: space-between;
}

.expert-tips-widget-left-element-container {
    width: 49%;
}

.expert-tips-widget-right-element-container {
    width: 49%;
}

.expert-tips-widget-title-row {
    display: flex;
}

.expert-tips-widget-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-right: 15px;
}

.expert-tips-widget-tips-container {
    width: 100%;
    height: 330px;
    border-radius: 20px;
    border: 1px black solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expert-tips-widget-tips-internal-container {
    width: 90%;
    height: 90%;
    resize: none;
    border: none;
}

.expert-tips-widget-tips-internal-container:focus {
    outline: none;
}