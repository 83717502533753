.login-success-container {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.login-success-heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
}

.login-success-copy {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
}