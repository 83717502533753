.old-appointment-widget-container {
    width: 100%;
    height: 214px;
    border-radius: 20px;
    border: 2px #EAEAEA solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EAEAEA;
}

.old-appointment-widget-internal-container {
    width: 95%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.old-appointment-widget-upper-element-heading {
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
}

.old-appointment-widget-upper-element-date {
    font-weight: 600;
    font-size: 35px;
    line-height: 40px;
}

.old-appointment-widget-regular-font {
    font-weight: 400;
}

.old-appointment-widget-bottom-element-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
}

.old-appointment-widget-bottom-left-element {
    display: flex;
    flex-direction: column;
}

.old-appointment-widget-bottom-element-expert-position {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
}

.old-appointment-widget-bottom-element-name {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
}

.old-appointment-widget-bottom-element-expert-role {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 104, 255, 1);
}

.old-appointment-widget-bottom-element-status-container {
    display: flex;
    justify-content: space-between;
    width: fit-content;
}

.old-appointment-widget-bottom-element-status-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
}

.old-appointment-widget-bottom-element-status-text-canceled {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: rgba(255, 58, 55, 1);
}