.customer-widget-container {
    width: 100%;
    height: 94px;
    border-radius: 20px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.customer-widget-internal-container {
    width: 95%;
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.customer-widget-left-element {
    height: 100%;
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.customer-widget-right-element {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.customer-widget-name {
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    color: rgba(0, 104, 255, 1);
}

.customer-widget-email {
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
}

.customer-widget-tax-code {
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
}

.customer-widget-plan {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: rgba(0, 104, 255, 1);
}