.login-page-buttons-row {
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.login-page-content {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-page-buttons-container {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.login-page-create-account-button {
    width: 159px;
    height: 55px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    color: black;
}

.login-page-login-form {
    width: 420px;
    height: 495px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.login-page-login-form-internal-container {
    width: 392px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.login-page-login-copy {
    width: 328px;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

.login-page-input-container {
    display: flex;
    flex-direction: column;
    width: 328px;
}

.login-page-input-label {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
}

.login-page-input-internal-container {
    display: flex;
    border-bottom: 1px black solid;
    align-items: center;
    justify-content: space-between;
}

.login-page-input-field {
    line-height: 5em;
    border: none;
    width: 98%;
    height: 40px;
}

.login-page-input-field:focus {
    outline: none;
}

.login-page-confirm-button {
    width: 328px;
    height: 55px;
    border: none;
    color: white;
    text-transform: uppercase;
    border-radius: 5px;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
}

.login-page-cant-login-button {
    width: 328px;
    height: 55px;
    background-color: white;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: black;
}

.login-page-show-password-container {
    cursor: pointer;
}