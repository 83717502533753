.plan-widget-container {
    display: flex;
    flex-direction: column;
}

.plan-widget-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.plan-widget-title-container {
    display: flex;
    justify-content: space-between;
    width: 320px;
}

.plan-widget-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

.plan-widget-edit-button {
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
    color: rgba(59, 130, 247, 1);
    cursor: pointer;
}

.plan-widget-publishing-date {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

.plan-widget-your-goal-container {
    width: 100%;
    height: 100px;
    background-color: rgba(139, 174, 225, 1);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.plan-widget-your-goal-internal-container {
    width: 95%;
    font-weight: 400;
    font-size: 30px;
    line-height: 20px;
    text-transform: uppercase;
    color: white;
}

.plan-widget-parameters-container {
    display: flex;
    flex-wrap: wrap;
}

.plan-widget-tips-area {
    width: 100%;
    height: 330px;
    display: flex;
    justify-content: space-between;
}

.plan-widget-tip-one {
    width: 48%;
    height: 100%;
    border-radius: 20px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.plan-widget-tip-one-internal {
    width: 90%;
    height: 90%;
}

.plan-widget-tip-two {
    width: 48%;
    height: 100%;
    border-radius: 20px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.plan-widget-tip-two-internal {
    width: 90%;
    height: 90%;
}

.plan-widget-tips-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

.plan-widget-tips-expert {
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
    color: rgba(0, 104, 255, 1);
}

.plan-widget-expert-key-points-container {
    height: calc(100% - 60px);
    width: 100%;
}

.plan-widget-expert-comment-container {
    height: calc(100% - 60px);
    width: 100%;
    overflow-y: auto;
}

.plan-widget-key-points-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 40px;
}

.plan-widget-key-point {
    margin-left: 25px;
    list-style-type: disc;
}

.plan-widget-expert-comment-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
}

.plan-widget-plan-card-container {
    height: 600px;
    width: 48%;
    border-radius: 20px;
    border: 1px rgba(217, 217, 217, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.plan-widget-plan-card-upper-element-container {
    width: 100%;
    height: 474px;
    background-color: rgba(240, 240, 240, 1);
    border-radius: 20px 20px 0 0;
}

.plan-widget-plan-card-bottom-element-container {
    width: 100%;
    height: 126px;
    border-radius: 0 0 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.plan-widget-plan-card-bottom-element-internal-container {
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.plan-widget-plan-card-bottom-element-date {
    font-weight: 700;
    font-size: 16px;
    line-height: 40px;
}

.plan-widget-plan-card-bottom-element-text {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}