.answer-card-container {
    width: 100%;
    height: 100px;
    border-radius: 10px;
    border: 1px black solid;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.answer-card-internal-container {
    width: 95%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.answer-card-left-element-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.answer-card-question {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}

.answer-card-answer {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
}

.answer-card-date {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
}