.create-plan-parameter-widget-container {
    width: 15%;
    aspect-ratio: 1 / 1;
    border: 1px black dashed;
    border-radius: 10px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.create-plan-parameter-widget-internal-container {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.create-plan-parameter-widget-heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 40px;
}

.create-plan-parameter-widget-image {
    height: 93px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}