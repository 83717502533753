.set-goal-widget-full-container {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.set-goal-widget-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

.set-goal-widget-container {
    width: 100%;
    height: 100px;
    background-color: rgba(139, 174, 225, 1);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.set-goal-widget-internal-container {
    width: 95%;
    font-weight: 400;
    font-size: 30px;
    line-height: 20px;
    text-transform: uppercase;
    color: white;
    display: flex;
    justify-content: space-between;
}

.set-goal-widget-edit-button {
    font-weight: 700px;
    font-size: 15px;
    line-height: 20px;
    cursor: pointer;
    position: relative;
}

.set-goal-widget-goal-dropdown {
    border-radius: 10px;
    position: absolute;
    width: 280px;
    height: 120px;
    background-color: rgba(220, 234, 255, 1);
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px rgba(234, 234, 234, 1) solid;
}

.set-goal-widget-goal-dropdown-internal {
    width: 250px;
    height: 100px;
}

.set-goal-widget-goal-dropdown-internal-element {
    width: 100%;
    height: 25px;
    color: black;
    font-size: 14px;
    line-height: 25px;
    border-radius: 5px;
    padding-left: 5px;
}

.set-goal-widget-goal-dropdown-internal-element:hover {
    background-color: white;
}