.account-sync-calendly-button {
    width: 408px;
    height: 55px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

.account-page-input-container {
    display: flex;
    flex-direction: column;
    width: 408px;
}

.account-page-input-label {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
}

.account-page-input-internal-container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px black solid;
    align-items: end;
}

.account-page-input-field {
    line-height: 5em;
    border: none;
    width: 98%;
    height: 40px;
}

.account-page-input-field:focus {
    outline: none;
}

.account-page-input-field:disabled {
    background-color: transparent;
}

.account-confirm-button {
    width: 408px;
    height: 55px;
    border: none;
    border-radius: 5px;
    color: white;
}

.account-page-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}

.account-page-password-hint {
    width: 408px;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: rgba(59, 130, 247, 1);
}

.account-page-professional-info-heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
}

.account-page-expert-area-container {
    width: 408px;
}

.account-page-skills-area {
    width: 408px;
}

.account-page-skills-area-heading {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
}

.account-page-skills-container {
    display: flex;
    flex-wrap: wrap;
}

.account-page-bio-area {
    width: 408px;
}

.account-page-bio-heading {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
}

.account-page-text-area {
    width: 398px;
    height: max-content;
    border: none;
    border-bottom: 1px black solid;
    resize: none;
    max-width: 100%;
    overflow: auto;
    padding: 5px;
    font-family: 'Roboto', sans-serif;
}

.account-page-text-area:focus {
    outline: none;
}

.account-page-counter {
    display: flex;
    justify-content: flex-end;
    font-weight: 700;
    font-size: 12px;
    line-height: 26px;
}

.account-page-logout-button-external-container {
    height: 55px;
    width: 150px;
    border-radius: 5px;
    border: 1px black solid;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.account-page-logout-button-external-container:hover {
    background-color: rgba(234, 234, 234, 1);
}

.account-page-logout-button-external-container:active {
    background-color: white;
}

.account-page-logout-button-internal-container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}