.survey-card-container {
    width: 32%;
    height: 237px;
    border-radius: 20px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.survey-card-internal-container {
    width: 90%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.survey-card-upper-element-container {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.survey-card-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
}

.survey-card-date {
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
}

.survey-card-bottom-element-container {
    width: 100%;
    height: 120px;
}

.survey-card-quiz-contents-heading {
    font-weight: 600;
    font-size: 14px;
    line-height: 26px; 
}

.survey-card-quiz-contents-content {
    font-weight: 400;
    font-size: 12px;
    line-height: 26px; 
}