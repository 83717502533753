.create-account-page-buttons-row {
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.create-account-page-content {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.create-account-page-buttons-container {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.create-account-page-login-button {
    width: 159px;
    height: 55px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.create-account-container {
    width: 420px;
    height: 550px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.create-account-internal-container {
    width: 392px;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.create-account-heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 24px;
}

.create-account-copy {
    width: 377px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
}

.create-account-linked-copy {
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

.create-account-checkbox-row {
    width: 328px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
}

.create-account-checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    border-radius: 8px;
    border: 1px black solid;
    margin-right: 10px;
    cursor: pointer;
}

.create-account-checkbox-internal-element {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    background-color: black;
}

.create-account-checkbox-copy {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 290px;
}

.create-account-checkbox-linked-copy {
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

.create-account-button {
    width: 328px;
    height: 55px;
    border-radius: 5px;
    border: 1px black solid;
    background-color: white;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
}