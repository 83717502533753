.drop-box-external-container {
    width: 100%;
    height: 165px;
    border-radius: 20px;
    border: 1px #EAEAEA solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.drop-box-internal-container {
    width: 95%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.drop-box-first-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.drop-box-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}

.drop-box-date {
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
}

.drop-box-button {
    width: 250px;
    height: 55px;
    background-color: white;
    border-radius: 5px;
    border: 1px black solid;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}