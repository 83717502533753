.next-apointment-widget-container {
    width: 100%;
    height: 214px;
    border-radius: 20px;
    border: 2px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.next-apointment-widget-container-urgent {
    width: 100%;
    height: 214px;
    border-radius: 20px;
    border: 2px rgba(0, 104, 255, 1) solid;
    background-color: rgba(239, 246, 255, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.next-apointment-widget-internal-container {
    width: 95%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.next-apointment-widget-upper-element-heading {
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
}

.next-apointment-widget-upper-element-date {
    font-weight: 600;
    font-size: 35px;
    line-height: 40px;
}

.next-apointment-widget-bottom-element-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.next-apointment-widget-bottom-element-name {
    font-weight: 700;
    font-size: 30px;
    line-height: 20px;
}

.next-apointment-widget-bottom-element-buttons-container {
    display: flex;
    justify-content: space-between;
    width: 430px;
}

.next-apointment-widget-bottom-element-edit-button {
    width: 167px;
    height: 55px;
    border-radius: 5px;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: white;
}

.next-apointment-widget-bottom-element-connect-button {
    width: 250px;
    height: 55px;
    border-radius: 5px;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: white;
}

.next-apointment-widget-red-text {
    color: rgba(255, 58, 55, 1);
}

.next-apointment-widget-regular-font {
    font-weight: 400;
}