.no-test-available-container {
    width: 500px;
    height: 100px;
    border-radius: 10px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    position: absolute;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-test-available-internal-container {
    width: 90%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.no-test-available-button-row {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.no-test-available-copy {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
}