.past-plan-box-external-container {
    width: 100%;
    height: 93px;
    border-radius: 10px;
    border: 1px black solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.past-plan-box-internal-container {
    width: 95%;
    height: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.past-plan-box-left-element {
    display: flex;
    flex-direction: column;
}

.past-plan-box-plan-date {
    font-weight: 700;
    font-size: 16px;
    line-height: 40px;
}

.past-plan-box-plan-name {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px; 
}