.back-button {
    width: 90px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.divider {
    width: 328px;
}

.divider-internal {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.divider-line {
    width: 45%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.2);
}

.divider-text {
    color: rgba(95, 95, 95, 1);
}

.loading_spinner {
    width: 60px;
    height: 60px;
    border: 3px solid rgba(59, 130, 247, 1);
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
  
  .spinner_container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }

  .single-divider {
    width: 100%;
    height: 1px;
    background-color: black;
  }

  .download-button {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    width: 90px;
  }

  .close-button {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }