.platform-experts-customers-single-patient-container {
    width: 90%;
    height: 95%;
    align-self: end;
}

.platform-experts-customers-single-patient-back-button-row {
    height: 60px;
    width: 89%;
}

.platform-experts-customers-single-patient-title-row {
    height: 150px;
    width: 89%;
    display: flex;
    justify-content: space-between;
}

.platform-experts-customers-single-patient-title-left-element {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.platform-experts-customers-single-patient-patient-name {
    font-weight: 700;
    color: rgba(0, 104, 255, 1);
}

.platform-experts-customers-single-patient-title-name {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
}

.platform-experts-customers-single-patient-title-plan {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: rgba(0, 104, 255, 1);
}

.platform-experts-customers-single-patient-title-email {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
}

.platform-experts-customers-single-patient-title-swab-code {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}

.platform-experts-customers-single-patient-title-right-element {
    width: 550px;
    display: flex;
    justify-content: space-between;
}

.platform-experts-customers-single-patient-title-reminder-button {
    width: 275px;
    height: 55px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.platform-experts-customers-single-patient-title-create-plan-button {
    width: 250px;
    height: 55px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.platform-experts-customers-single-patient-content {
    height: calc(100% - 210px);
    width: 100%;
    overflow-y: auto;
}

.platform-experts-customers-single-patient-content-container {
    height: 100%;
    width: 89%;
}

.platform-experts-customers-single-patient-back-button {
    display: flex;
    justify-content: space-between;
    width: 175px;
    height: 55px;
    align-items: center;
    cursor: pointer;
}

.platform-experts-customers-single-patient-back-button-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
}