/* reset */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* fine reset */

/* html, body, .root, .App {
  height: 100%;
} */

.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../public/images/holifyaLogos/background-logo.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  font-family: 'Roboto', sans-serif;
}

.Platform {
  background-image: none;
}

@media (max-width: 480px) {
  .App {
    background-image: none;
  }
}

/*  */

.primary-action-color {
  color: rgba(59, 130, 247, 1);
}

.primary-action-color-background {
  background-color: rgba(59, 130, 247, 1);
}

.primary-action-color-background:hover {
  background-color: rgba(70, 140, 255, 1);
}

.primary-action-color-background:active {
  background-color: rgba(35, 107, 226, 1);
}

.primary-action-color-background:disabled {
  background-color: rgba(59, 130, 247, 0.5);
}

.secondary-action-color {
  color: rgba(40, 231, 197, 1);
}

.secondary-action-color-background {
  background-color: rgba(40, 231, 197, 1);
}

.secondary-action-color-background:hover {
  background-color: rgba(53, 242, 208, 1);
}

.secondary-action-color-background:active {
  background-color: rgba(23, 215, 181, 1);
}

.secondary-action-color-background:disabled {
  background-color: rgba(40, 231, 197, 0.5);
}

.ternary-action-color-background {
  background-color: rgba(146, 146, 146, 1);
}

.ternary-action-color-background:hover {
  background-color: rgba(160, 160, 160, 1);
}

.ternary-action-color-background:disabled {
  background-color: rgba(146, 146, 146, 0.5);
}

.ternary-action-color-background:active {
  background-color: rgba(130, 129, 129, 1);
}

.black-border {
  border: 1px black solid;
}

/*  */

.lh0 {
  line-height: 0;
}

/*  */

.standard-uppercase-text {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
}

/*  */

.horizontal-flex-group {
  display: flex;
}

.vertical-flex-group {
  display: flex;
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.invisible {
  visibility: hidden;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.left-10 {
  left: 10px;
}

.visible-0-percent {
  filter: opacity(0%);
}

.visible-10-percent {
  filter: opacity(10%);
}

.visible-30-percent {
  filter: opacity(30%);
}

.inactive {
  pointer-events: none;
}

.width-50-percent {
  width: 50%;
}

.width-100-percent {
  width: 100%;
}

.height-5 {
  height: 5px;
}

.height-10 {
  height: 10px;
}

.height-20 {
  height: 20px;
}

.height-35 {
  height: 35px;
}

.height-100-percent {
  height: 100%;
}

.center-div {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.margin-left-10 {
  margin-left: 10px;
}

iframe {
  border-radius: 10px 10px 0 0 ;
}