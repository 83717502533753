.file-section-widget-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.file-section-widget-files-container {
    width: 48%;
    height: 50px;
}

.file-section-widget-share-file-button {
    width: 100%;
    height: 55px;
    border-radius: 5px;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.file-section-widget-past-plans-full-container {
    width: 48%;
    height: 50px;
}

.file-section-widget-past-plans-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-left: 20px;
}

.file-section-widget-past-plans-container {
    width: 100%;
    border-radius: 20px;
    border: 1px #EAEAEA solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.file-section-widget-past-plans-container-height-1 {
    height: 165px;
}

.file-section-widget-past-plans-container-height2 {
    height: 273px;
}

.file-section-widget-past-plans-container-height-3 {
    height: 390px;
}

.file-section-widget-past-plans-container-height-4 {
    height: 520px;
}

.file-section-widget-past-plans-container-height-5 {
    height: 660px;
}

.file-section-widget-past-plans-container-height-6 {
    height: 810px;
}

.file-section-widget-past-plans-internal-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.file-section-widget-past-plans-internal-container-height-1 {
    height: 93px;
}

.file-section-widget-past-plans-internal-container-height2 {
    height: 205px;
}

.file-section-widget-past-plans-internal-container-height-3 {
    height: 335px;
}

.file-section-widget-past-plans-internal-container-height-4 {
    height: 475px;
}

.file-section-widget-past-plans-internal-container-height-5 {
    height: 625px;
}

.file-section-widget-past-plans-internal-container-height-6 {
    height: 785px;
}