.platform-experts-no-navbar-container {
    height: 100%;
    width: calc(100% - 120px);
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.platform-experts-dashboard-container {
    width: 90%;
    height: 90%;
    align-self: end;
}

.platform-experts-dashboard-title-row {
    width: 89%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.platform-experts-dashboard-title-container {
    display: flex;
}

.platform-experts-dashboard-title-logo-container {
    margin-right: 10px;
    line-height: 0;
}

.platform-experts-dashboard-title {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
}

.platform-experts-dashboard-content-container {
    width: 100%;
    height: 90%;
    overflow-y: auto;
}

.platform-experts-final-element-container {
    width: 89%;
    height: 100%;
}

.platform-expert-content-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
}