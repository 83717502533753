.key-parameters-dialog-external-container {
    width: 700px;
    height: 880px;
    border-radius: 20px;
    border: 1px rgba(234, 234, 234, 1) solid;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.key-parameters-dialog-internal-container {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.key-parameters-dialog-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.key-parameters-dialog-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
}

.key-parameters-dialog-copy {
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
}

.key-parameters-dialog-widgets-container {
    width: 100%;
    height: 630px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.key-parameters-dialog-buttons-row {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.key-parameters-dialog-undo-button {
    width: 120px;
    height: 55px;
    background-color: white;
    border: 1px black solid;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 15px;
}

.key-parameters-dialog-confirm-button {
    width: 150px;
    height: 55px;
    border: 1px black solid;
    color: white;
    border: none;
    border-radius: 5px;
    margin-left: 15px;
}