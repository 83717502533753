.important-parameters-widget-title-row {
    display: flex;
}

.important-parameters-widget-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-right: 15px;
}

.important-parameters-widget-edit-button {
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
    color: rgba(59, 130, 247, 1);
    margin-left: 15px;
    cursor: pointer;
}

.important-parameters-widget-key-parameters-row {
    display: flex;
    justify-content: space-between;
}

.important-parameters-widget-key-parameters-add {
    width: 15%;
    aspect-ratio: 1 / 1;
    border: 1px black dashed;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 40px;
    cursor: pointer;
}